const http = (name, phone) => {
  try {
    const url =
      "https://py2k7yrnp5.execute-api.us-east-1.amazonaws.com/prod/graphql"
    const mutation = `
        mutation createLandingLead($name: String!, $phone: String!) {
            createLandingLead(name: $name, phone: $phone) {
                ok
            }
        }
    `
    return fetch(url, {
      method: "POST", // or 'PUT'
      body: JSON.stringify({
        query: mutation,
        variables: { name: name, phone: phone },
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
  } catch (e) {
    throw new Error("something")
  }
}
export { http }
