import styled from 'styled-components'

import iHappyGuyMobile from '../../images/header-image-mobile@2x.png'
import happyGuyMobile from '../../images/header-image-mobile@2x.webp'
import iHappyGuyWide from '../../images/header-image@2x.png'
import iHappyGuyTablet from '../../images/header-image@2x.png'
import happyGuyWide from '../../images/header-image@2x.webp'
import happyGuyTablet from '../../images/header-image@2x.webp'
import { device } from '../../styles/devices'

export const Featured = styled.div`
  display: grid;

  @media all and ${device.laptopXS} {
    height: 500px;
  }

  @media all and ${device.laptop} {
    height: 515px;
  }

  @media all and ${device.laptopM} {
    height: 523px;
  }

  @media all and ${device.laptopL} {
    height: 590px;
  }

  @media all and ${device.desktopXS} {
    height: 750px;
  }
`

export const CoverText = styled.div`
  background-color: #dc0048;
  border: 1px solid #dc0048;
  margin-top: -12px;

  h3 {
    font-family: 'DonaAltBold';
    font-size: 1.8rem;
    color: #fff;
    text-align: center;
    padding: 40px 16px 16px 16px;
  }
  p {
    color: #fff757;
    font-family: 'DonaAltRegular';
    font-size: 0.9rem;
    text-align: center;
    padding: 16px;
    text-transform: uppercase;
  }

  @media all and ${device.tablet} {
    h3 {
      font-size: 2rem;
    }
    p {
      width: 86%;
      font-size: 1.5rem;
      margin: -13px auto;
    }
  }

  @media all and ${device.laptopXS} {
    background-color: transparent;
    order: 2;
    position: relative;
    border: none;
    z-index: 1;
    top: -568px;
    padding: 109px 52px;
    margin-top: 0;
    h3 {
      width: 62%;
      font-size: 3rem;
      text-align: left;
    }
    p {
      font-size: 1.2rem;
      text-align: left;
      width: 62%;
      line-height: 1.4;
      margin: 0;
    }
  }

  @media all and ${device.laptop} {
    h3 {
      width: 49%;
    }
    p {
      width: 60%;
      font-size: 1.7rem;
    }
  }

  @media all and ${device.laptopL} {
    top: -666px;
  }

  @media all and ${device.desktopXS} {
    top: -713px;

    h3 {
      width: 42%;
      font-size: 4rem;
    }

    p {
      width: 45%;
    }
  }
`

export const Cover = styled.div`
  background-image: url(${happyGuyMobile});
  background-size: cover;
  background-position: center;
  height: 352px;

  /* Only works on safari */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      background-image: url(${iHappyGuyMobile});
    }
  }

  @media all and ${device.tablet} {
    order: 1;
    height: 500px;
    background-image: url(${happyGuyTablet});
    background-position: right;

    /* Only works on safari */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        background-image: url(${iHappyGuyTablet});
      }
    }
  }

  @media all and ${device.laptopXS} {
    order: 1;
    height: 536px;
    background-image: url(${happyGuyWide});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    /* Only works on safari */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        background-image: url(${iHappyGuyWide});
      }
    }
  }

  @media all and ${device.laptop} {
    height: 620px;
  }

  @media all and ${device.laptopL} {
    height: 682px;
  }

  @media all and ${device.desktopXS} {
    height: 852px;
    background-image: url(${happyGuyWide});
    background-position: center;

    /* Only works on safari */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        background-image: url(${iHappyGuyWide});
      }
    }
  }
`
