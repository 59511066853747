import React from 'react'

import * as Styles from './styles'

const Cover: React.FC = () => (
  <Styles.Featured>
    <Styles.CoverText>
      <h3>O jeito fácil de abastecer o seu mercado</h3>
      <p>ENCONTRE OS MELHORES PREÇOS E PRODUTOS PARA O SEU MERCADO SEM SAIR DA SUA LOJA.</p>
    </Styles.CoverText>
    <Styles.Cover />
  </Styles.Featured>
)

export default Cover
