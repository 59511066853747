import React, { useState } from "react"

import { Container, Content, Button } from "./styles"

const isBrowser = typeof window !== "undefined"

const LgpdModal = () => {
  const hasAcceptedCookies = isBrowser
    ? localStorage.getItem("acceptedCookies") || false
    : false

  const [show, setShow] = useState(!hasAcceptedCookies)

  const allowedCookies = () => {
    if (isBrowser) {
      localStorage.setItem("acceptedCookies", String(true))
    }
    setShow(!!hasAcceptedCookies)
  }

  return (
    <>
      {show && (
        <Container data-testid="modal-lgpd">
          <Content>
            <p>
              A gente guarda estatísticas de visitas para melhorar a sua
              experiência de navegação. <br />
              Veja mais em{" "}
              <a
                href="https://www.mercedobairro.com/politica-de-privacidade"
                target="_blank"
                rel="noopener noreferrer"
              >
                política de privacidade.
              </a>
            </p>
            <Button
              className="btn btn-retail btn-block"
              onClick={allowedCookies}
              data-testid="btn-lgpd"
            >
              Aceitar e continuar
            </Button>
          </Content>
        </Container>
      )}
    </>
  )
}

export default LgpdModal
