import React from 'react'
import Helmet from 'react-helmet'
import MaskedInput from 'react-text-mask'

import Cover from '../components/Cover'
import Layout from '../components/Layout'
import * as Styles from '../components/Layout/styles'
import LgpdModal from '../components/LgpdModal'
import SEO from '../components/seo'
import { http } from '../http'
import SuccessIcon from '../images/check-circle.svg'
import bizNews from '../images/landing/biznews.png'
import braziljournal from '../images/landing/braziljournal.png'
import endeavor from '../images/landing/endeavor.png'
import folha from '../images/landing/folha.png'
import fortune from '../images/landing/fortune.png'
import itforum from '../images/landing/itforum.png'
import labs from '../images/landing/labs.png'
import oasislab from '../images/landing/oasislab.png'
import safra from '../images/landing/safra.png'
import sbvc from '../images/landing/sbvc.png'
import startupi from '../images/landing/startupi.png'
import venture from '../images/landing/venture.png'
export default class IndexPage extends React.Component {
  state = {
    name: '',
    phone: '',
    checkInput: false,
    registered: true,
    messageSuccess: false,
  }

  componentDidMount = () => {
    window.addEventListener('scroll', function () {
      if (this.scrollY < 800) {
        document.getElementById('btn-scroll')!.style.opacity = '1'
      } else if (this.scrollY >= 800) {
        document.getElementById('btn-scroll')!.style.opacity = '0'
      }
    })
  }

  sendEmailToServer = async (e) => {
    e.preventDefault()
    if (this.state.name && this.state.phone !== '') {
      this.setState({
        checkInput: true,
      })
      try {
        await http(this.state.name, this.state.phone)
        this.setState({
          registered: false,
          messageSuccess: true,
        })
      } catch {}
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  validateAll = () => {
    if (this.state.name && this.state.phone !== '') {
      this.setState({ checkInput: true })
    }
  }

  goToSection = () => {
    document.getElementById('quero-fazer-parte')!.scrollIntoView({
      behavior: 'smooth',
    })
  }

  render() {
    return (
      <>
        <Helmet>
          <meta name="format-detection" content="telephone=no" />
        </Helmet>
        <Layout>
          <SEO title="Home" />
          <Cover />
          <Styles.Content id="main-content">
            <Styles.Button onClick={this.goToSection} id="btn-scroll">
              <label>Quero fazer parte</label>
            </Styles.Button>
            <Styles.Title>
              <h4>É muito fácil e rápido!</h4>
              <p>Veja como funciona:</p>
            </Styles.Title>
            <Styles.HowItWorks>
              <Styles.HowItWorksItem>
                <p>1</p>
                <div>
                  <label>
                    Compare os preços <br />e economize
                  </label>
                  <span>
                    Compare os preços de vários atacados em nosso site para garantir as melhores
                    ofertas.
                  </span>
                </div>
              </Styles.HowItWorksItem>
              <Styles.HowItWorksItem>
                <p>2</p>
                <div>
                  <label>Compre os produtos sem sair da sua loja</label>
                  <span>
                    Você não precisa ir ao atacado para comprar os produtos para o seu mercado.
                  </span>
                </div>
              </Styles.HowItWorksItem>
              <Styles.HowItWorksItem>
                <p>3</p>
                <div>
                  <label>Receba as mercadorias em até 24h</label>
                  <span>Nós separamos e entregamos todos os seus produtos no dia seguinte.</span>
                </div>
              </Styles.HowItWorksItem>
              <Styles.HowItWorksItem>
                <p>4</p>
                <div>
                  <label>Pague só depois de receber</label>
                  <span>Você tem 7 dias úteis para pagar as suas compras</span>
                </div>
              </Styles.HowItWorksItem>
            </Styles.HowItWorks>
          </Styles.Content>
          <Styles.BePart id="quero-fazer-parte">
            <h4>Faça parte agora mesmo!</h4>
            {this.state.messageSuccess ? (
              <Styles.Success>
                <img src={SuccessIcon} alt="email enviado com sucesso" />
                <label>Tudo certo!</label>
                <span>Em até 24h entraremos em contato com você!</span>
              </Styles.Success>
            ) : null}
            {this.state.registered ? (
              <Styles.Register>
                <label>Informe seus dados</label>
                <span>e entramos em contato com você.</span>
                <Styles.Form onSubmit={this.sendEmailToServer}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Informe seu nome"
                    onChange={this.handleChange}
                  />
                  <MaskedInput
                    type="text"
                    name="phone"
                    guide={false}
                    placeholder="Qual o seu telefone?"
                    onChange={this.handleChange}
                    mask={[
                      '(',
                      /[1-9]/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                  />
                  <button type="submit" disabled={this.state.checkInput}>
                    Quero fazer parte
                  </button>
                </Styles.Form>
              </Styles.Register>
            ) : null}
          </Styles.BePart>
          <Styles.Content>
            <Styles.Title>
              <h4>Mercê na mídia</h4>
            </Styles.Title>

            <Styles.ImgGrid>
              <a
                href="https://www1.folha.uol.com.br/mpme/2021/11/startup-liga-donos-de-minimercados-a-fornecedores-e-facilita-negociacoes.shtml?utm_source=whatsapp&utm_medium=social&utm_campaign=compwa"
                target="_blank"
                rel="noreferrer"
              >
                <img src={folha} alt="" />
              </a>
              <a
                href="https://braziljournal.com/os-fundadores-do-ifood-e-ze-delivery-agora-miram-os-mercadinhos-de-bairro"
                target="_blank"
                rel="noreferrer"
              >
                <img src={braziljournal} alt="" />
              </a>
              <a
                href="https://biznews.com.br/os-fundadores-do-ifood-e-ze-delivery-agora-miram-os-mercadinhos-de-bairro/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={bizNews} alt="" />
              </a>
              {/* <a href="" target="_blank"
              rel="noreferrer">
                <img src={computerworld} alt="" />
              </a> */}
              <a
                href="https://endeavor.org.br/sobre-a-endeavor/conheca-as-14-selecionadas-do-programa-scale-up-endeavor-solucoes-smb/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={endeavor} alt="" />
              </a>
              <a
                href="https://fortune.com/2021/10/26/meet-20-small-companies-looking-to-save-the-future-of-esg/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={fortune} alt="" />
              </a>
              <a
                href="https://itforum.com.br/noticias/criada-por-fundadores-de-ifood-e-ze-delivery-merce-do-bairro-levanta-r-53-milhoes/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={itforum} alt="" />
              </a>
              <a
                href="https://labsnews.com/pt-br/noticias/negocios/startup-de-fundadores-do-ifood-e-ze-delivery-aposta-nos-mercadinhos-de-bairro-e-recebe-serie-a-de-r-53-milhoes/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={labs} alt="" />
              </a>
              <a
                href="https://oasislab.com.br/os-fundadores-do-ifood-e-ze-delivery-agora-miram-os-mercadinhos-de-bairro/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={oasislab} alt="" />
              </a>
              <a
                href="https://www.youtube.com/watch?v=T00n6BSHTyw"
                target="_blank"
                rel="noreferrer"
              >
                <img src={safra} alt="" />
              </a>
              <a
                href="https://sbvc.com.br/os-fundadores-do-ifood-e-ze-delivery-agora-miram-os-mercadinhos-de-bairro/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={sbvc} alt="" />
              </a>
              <a
                href="https://startupi.com.br/2021/10/startup-que-oferece-marketplace-para-pequenos-mercados-recebe-aporte-de-r-53-milhoes/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={startupi} alt="" />
              </a>
              <a
                href="https://www.venturecapitaljournal.com/merce-do-bairro-garners-10m-series-a/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={venture} alt="" />
              </a>
            </Styles.ImgGrid>
          </Styles.Content>
        </Layout>
        <LgpdModal />
      </>
    )
  }
}
