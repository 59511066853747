import styled from "styled-components"

export const Container = styled.div`
  bottom: 0;
  width: 100%;
  z-index: 12;
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0px -1px 4px #00000029;
  padding: 16px 0;
  @media (min-width: 992px) {
    padding: 8px 0;
  }
`
export const Content = styled.div`
  align-items: baseline;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: 16px;
  a {
    color: #065fd4;
    text-decoration: underline;
  }

  p {
    line-height: 1.4;
    margin-bottom: 12px;
  }

  @media (min-width: 992px) {
    br {
      display: none;
    }
    display: flex;
    justify-content: space-between;
  }
`
export const Button = styled.button`
  cursor: pointer;
  width: 100%;
  background: rgb(6, 95, 212);
  color: rgb(255, 255, 255);
  font-weight: bold;
  min-height: 40px;
  text-align: center;
  border-radius: 20px;
  @media (min-width: 992px) {
    width: 28%;
    margin-left: 20px;
  }
`
